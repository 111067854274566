import firebase from 'firebase';

export interface RequestResponse {
  access_token: string;
  expires_in: number;
  token_type: string;
  scope: string;
}

export const enum PescheckScreeningEvent {
  WATCHLIST_RESULT = 'WATCHLIST_RESULT',
  STATUS_CHANGED = 'STATUS_CHANGED',
}

export const enum WatchlistCheck {
  FINISHED = 'FINISHED',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
  PROCESSING = 'PROCESSING',
  CREATED = 'CREATED',
}
export interface PescheckCreateScreeningResponse {
  id: string;
  full_name: string;
  status: string;
  organisation: string;
  email: string;
  first_name: string;
  last_name: string;
  watchlist_date_of_birth: string;
  watchlist_threshold: string;
  bucket: {
    vog_org_name: string;
    watchlist_threshold: string;
  },
  checks: {
    watchlistcheck: WatchlistCheck;
  },
  created_at: string; // 2022-09-07T15:02:46.135153+02:00
  updated_at: string;
  initials: string;
}

export interface UserData {
  email: string;
  last_name: string;
  first_name: string;
  watchlist_threshold?: string,
  watchlist_date_of_birth?: string, // YYYY-MM-DD
  watchlist_notes?: string,
}

export const enum PeschecScreeningStatus {
  FINISHED = 'FINISHED',
  WARNING = 'WARNING',
  OPEN = 'OPEN',
  WAITING = 'WAITING',
  ATTENTION = 'ATTENTION',
}

export const enum PescheckGenders {
  Male = 'Male',
  Female = 'Female',
}

export enum PescheckDatasets {
  'PEP-CURRENT' = 'PEP-CURRENT', // Search only the current Politically Exposed Persons
  'PEP-FORMER' = 'PEP-FORMER', // Search only the former Politically Exposed Persons
  'PEP-LINKED' = 'PEP-LINKED', // Search only the Politically Exposed Persons by association
  'SAN-CURRENT' = 'SAN-CURRENT', // Search only current Sanctions
  'SAN-FORMER' = 'SAN-FORMER', // Search only former Sanctions
  RRE = 'RRE', // Search the Reputational Risk Exposure dataset
  REL = 'REL', // Search the Regulatory Enforcement Lists
  INS = 'INS', // Search the Insolvency Register
  // ^^^ Fields above to be deprecated ^^^

  SAN = 'SAN', // Search all Sanctions: current and former
  PEP = 'PEP', // Search all Politically Exposed Persons: current, former and linked
  POI = 'POI', // Search the Profiles of Interest dataset
  DD = 'DD', // Search the Disqualified Directors dataset
  IIR = 'IRR',
  RCA = 'RCA',
  Crime = 'Crime',
  Theft = ' Theft',
  Diplomat = 'Diplomat',
  Bank = 'Bank',
  'Debarred entity' = 'Debarred entity',
  Judge = 'Judge',
  Terrorism = 'Terrorism',
  'State-owned enterprise' = 'State-owned enterprise',
  Oligarch = 'Oligarch',
  Offshore = 'Offshore',
  'War crimes' = 'War crimes',
  Spy = 'Spy',
  'Intergovernmental organization' = 'Intergovernmental organization',
  'Crime leadership' = 'Crime leadership',
}

export type PescheckDatasetsValue = `${PescheckDatasets}`

export interface PescheckScreeningMatches {
  qrCode: number,
  resourceId: string,
  score: number,
  match: string,
  name: string,
  countries: string[],
  datasets: PescheckDatasets[],
  version: number,
  datesOfBirth: number[],
  gender: PescheckGenders,
}
export interface PescheckScreeningResult {
  event: PescheckScreeningEvent,
  report: string,
  screening_id: string,
  status: PeschecScreeningStatus,
  watchlist_notes?: string,
  watchlist: {
    results: {
      matchCount: number,
      matches: PescheckScreeningMatches[],
    }
  },
  isFalsePositivePep?: boolean;
  isFalsePositiveScreening?: boolean;
  pepRationale?: string;
  screeningRationale?: string;
}

export interface Pescheckv3PescheckDocument {
  initialRequest: PescheckCreateScreeningResponse,
  finalResult?: PescheckScreeningResult,
  createdDateTime: firebase.firestore.Timestamp;
  updatedDateTime: firebase.firestore.Timestamp;
}
