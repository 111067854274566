



















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class FormFileInputLabel extends Vue {
  @Prop({ required: true }) file!: any;
  @Prop() loading!: boolean;
}
