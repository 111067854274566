













































































































































































































































































































































































































































































import { Component, Ref, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter, State as StateClass } from 'vuex-class';
// @ts-ignore
import { ADD_TOAST_MESSAGE as addToastMessage } from 'vuex-toast';
import to from 'await-to-js';
import axios from 'axios';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import firebase from 'firebase/app';
import { BusinessIdentificationImages, Investor, isInvestor, KYCMethods, User, UserStatus, UserTier } from '@/models/users/User';
import { DataContainerStatus } from '@/models/Common';
import { State } from '@/models/State';
import ModifyStatusModal from '@/components/users/ModifyStatusModal.vue';
import FormInput from '@/components/common/form-elements/FormInput.vue';
import FormDatePicker from '@/components/common/form-elements/FormDatePicker.vue';
import UploadResource from '@/components/common/UploadResource.vue';
import { timestampToDate, transformDate } from '@/filters/date';
import singleDocumentQuery from '@/mixins/singleDocumentQuery';
import { bloqifyFirestore, bloqifyStorage } from '@/boot/firebase';
import UserDetailsSlice from '@/components/user/UserDetailsSlice.vue';
import FormSelect from '@/components/common/form-elements/FormSelect.vue';
import Modal from '@/components/common/Modal.vue';
import Lightbox from '@/components/common/Lightbox.vue';

import Questionnaire from '@/components/user/questionnaire/Questionnaire.vue';
import { UserData } from '@/models/identification-requests/pescheck';

@Component({
  components: {
    ModifyStatusModal,
    Modal,
    FormInput,
    FormDatePicker,
    UploadResource,
    FormSelect,
    ValidationObserver,
    ValidationProvider,
    UserDetailsSlice,
    Questionnaire,
    Lightbox,
  },
  mixins: [
    singleDocumentQuery({
      ref: bloqifyFirestore.collection('investors'),
      stateSlice: 'boundUser',
      idName: 'userId',
    }),
  ],
})
export default class CreateUser extends Vue {
  @StateClass('user') stateUser!: State['user'];
  @StateClass('boundUser') boundUser!: State['boundUser'];

  @Ref('form') readonly form!: HTMLFormElement;
  @Ref('questionnaireRef') readonly questionnaireRef!: Questionnaire;

  @Action bindFirestoreReferences!: Function;
  @Action(addToastMessage) addToastMessage!: Function;
  @Action createUser!: Function;
  @Action updateUser!: (user: User) => Promise<void>;
  @Getter getQuestionnaireUsed!: boolean;

  user: { [key: string]: any } = {};

  UserTier = UserTier;
  userType: 'idin' | 'business' | 'private' | null = null;
  showModifyStatusModal: boolean = false;

  dateOfBirthAsDate: Date | null = null;

  liteboxImages: string[] = [];
  showLightbox = false;
  currentDocumentUrl = '';
  imgLoading = false;
  confirmModalOpen = false;
  editQuestionnaire = false;

  @Watch('boundUser', { immediate: true })
  async onBoundUserChange(newUser: State['boundUser']): Promise<void> {
    if (newUser) {
      this.user = { ...newUser };

      this.dateOfBirthAsDate = timestampToDate(this.user.dateOfBirth) || null;

      const loadImages = async (imagePath: BusinessIdentificationImages): Promise<void> => {
        if (!this.user[imagePath]) {
          return;
        }

        const storageRef = bloqifyStorage.ref().child(this.user[imagePath]);
        const [getError, fileUrl] = await to(storageRef.getDownloadURL());
        if (getError) {
          this.addToastMessage({
            text: getError.message || 'There was an error retrieving the files.',
            type: 'danger',
          });
          throw getError;
        }

        const [getMetadataError, metadata] = await to(storageRef.getMetadata());
        if (getMetadataError) {
          this.addToastMessage({
            text: getMetadataError.message || 'There was an error retrieving the files.',
            type: 'danger',
          });
          throw getMetadataError;
        }

        const { contentType, name: fileName } = metadata;

        const [getFileError, response] = await to(axios.get(
          fileUrl,
          {
            responseType: 'arraybuffer',
          },
        ));
        if (getFileError) {
          this.addToastMessage({
            text: getFileError.message || 'There was an error retrieving the files.',
            type: 'danger',
          });
          throw getFileError;
        }

        const responseBlob = response!.data as Blob;
        const reader = new FileReader();

        reader.readAsDataURL(new Blob([responseBlob], { type: contentType }));
        reader.onload = (f) => {
          const customFile = {
            url: f.target?.result || '',
            file: new File([responseBlob], fileName, { type: contentType }),
          };

          // @ts-ignore
          this.user[imagePath] = customFile;
        };
      };

      // Files
      this.imgLoading = true;
      await Promise.all([
        loadImages('passport'),
        loadImages('kvkImage'),
      ]);
      this.imgLoading = false;
    }
    // default type to private
    if (!this.userType) {
      this.changeType(KYCMethods.Private);
    }
  }

  @Watch('stateUser.error')
  onNewUserError(newError: firebase.FirebaseError) {
    if (newError) {
      if (newError.message === 'Not Found') {
        this.$router.replace('/404');
      }
      this.addToastMessage({
        text: newError.message || newError,
        type: 'danger',
      });
    }
  }

  @Watch('stateUser.status')
  async onStatusChange(newStatus: DataContainerStatus) {
    if (newStatus === DataContainerStatus.Success) {
      if (this.stateUser?.operation === 'createUser') {
        let errorQuestionnaire: Error | null | undefined;
        if (this.getQuestionnaireUsed) {
          [errorQuestionnaire] = await to(this.questionnaireRef.submit(this.stateUser?.payload));
        }
        if (!errorQuestionnaire) {
          this.addToastMessage({
            text: 'User correctly created',
            type: 'success',
          });
          await this.$router.replace(`/user-details/${this.stateUser?.payload}`);
        }
      } else if (this.stateUser?.operation === 'updateUser') {
        this.addToastMessage({
          text: 'User correctly updated',
          type: 'success',
        });
      }
    }
  }

  @Watch('user', { immediate: true })
  onNewUser(newUser: User | Investor): void {
    if (newUser && isInvestor(newUser)) {
      if (newUser.kycMethod === KYCMethods.Idin) {
        this.userType = 'idin';
      }
      if (newUser.kycMethod === KYCMethods.Business) {
        this.userType = 'business';
      }
      if (newUser.kycMethod === KYCMethods.Private) {
        this.userType = 'private';
      }
    }
  }

  get userId(): string | undefined {
    return this.$route.params.userId;
  }

  get userPayload(): User | null {
    return this.stateUser?.payload;
  }

  get userCreatedAt(): String {
    return transformDate(this.user.createdDateTime);
  }

  get upgradeUser(): boolean {
    return !!this.userId && (this.user.tier === UserTier.Account);
  }

  get loadingUser(): boolean | null {
    return this.stateUser?.status === DataContainerStatus.Processing;
  }

  get isEnabled(): boolean {
    return this.user.status === UserStatus.Enabled;
  }

  updateDateOfBirth(newDate: Date) {
    this.dateOfBirthAsDate = newDate;
    (this.user.dateOfBirth as any) = firebase.firestore.Timestamp.fromMillis(newDate.valueOf());
  }

  onFileChange(event: any, which: BusinessIdentificationImages): void {
    const file = (event.target?.files[0] || event.dataTransfer?.files[0]) as File;
    if (!file) {
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (f: any) => {
      const customFile = {
        url: f.target?.result || '',
        file,
      };

      this.user[which] = customFile;
    };
  }

  changeType(newType: KYCMethods) {
    this.userType = newType;
  }

  /**
   * Show image with litebox
   */
  async openImage(which: BusinessIdentificationImages): Promise<void> {
    this.currentDocumentUrl = '';
    this.liteboxImages = [];

    if (this.user[which]?.file?.type === 'application/pdf') {
      this.currentDocumentUrl = this.user[which].url;
      this.showLightbox = true;
      return;
    }

    this.liteboxImages = [this.user[which].url];
    this.showLightbox = true;
  }

  closeModal(): void {
    this.showModifyStatusModal = false;
    // Set the toggle back if the status change was cancelled
    (this.$refs.toggle as HTMLInputElement).checked = this.user.status === UserStatus.Enabled;
  }

  submitUserClick(): void {
    if (this.userId && this.user.tier === UserTier.Account) {
      this.confirmModalOpen = true;
    } else {
      this.submitUser();
    }
  }

  closeConfirmModal(): void {
    this.addToastMessage({
      text: 'Saving is only possible with upgrading',
      type: 'warning',
    });
  }

  async submitUser(): Promise<void> {
    const user: { [key: string]: any } = { ...this.user };
    if (this.userId) {
      user.uid = this.userId;
    }
    // Remove empty fields
    Object.keys(user).forEach((key: string): void => {
      if (user[key] === null || user[key] === undefined) {
        delete user[key];
      }
    });

    // Remove data we dont need to send
    delete user.createdDateTime;
    delete user.identificationRequest;
    delete user.pescheck;
    delete user.idin;
    delete user.passwordRepeat;
    delete user.updatedDateTime;
    delete user.createdDateTime;
    const { identificationRequest, idin, ...restOfUser } = user;
    if (restOfUser.passport) {
      restOfUser.passport = restOfUser.passport.file;
    }
    if (restOfUser.kvkImage) {
      restOfUser.kvkImage = restOfUser.kvkImage.file;
    }

    if (user.tier === UserTier.Investor) {
      // we don't need to upgrade so just save the edits
      await this.updateUser(restOfUser as User);
    } else {
      // Here either the user doesn't exist yet or it's only of type Account
      if (this.userType === 'business') {
        restOfUser.kycMethod = KYCMethods.Business;
      }
      if (this.userType === 'private') {
        restOfUser.kycMethod = KYCMethods.Private;
      }
      restOfUser.tier = UserTier.Investor;

      if (!this.userId) {
        await this.createUser(restOfUser as User);
      } else {
        await this.updateUser(restOfUser as User);
        if (this.confirmModalOpen) {
          this.confirmModalOpen = false;
        }
      }
    }

    if (this.userId) {
      if (this.getQuestionnaireUsed) {
        // also save the questionnaire after we have the userID
        await this.questionnaireRef.submit(this.userId);
      }
      // after updating we need to get the new data, but we go to details if this user was just created
      this.bindFirestoreReferences([
        {
          name: 'boundUser',
          ref: bloqifyFirestore.collection('investors').doc(this.userId),
        },
      ]);
      this.form.reset();
    }
  }
}
