





















import { Component, Prop, Vue } from 'vue-property-decorator';
import firebase from 'firebase/app';
import { transformDate } from '@/filters/date';
import FormInput from '@/components/common/form-elements/FormInput.vue';

type Data = string | undefined | null | Date | number | firebase.firestore.Timestamp;

@Component({
  components: {
    FormInput,
  },
})

export default class UserDetailsSlice extends Vue {
  @Prop({ required: false }) label!: string;
  @Prop() data!: Data;
  @Prop({ required: false }) icon!: string;

  get text(): string {
    if (!this.data) { return '–'; }
    if (typeof this.data === 'string') { return this.data; }
    if (typeof this.data === 'number') { return `${this.data}`; }
    return transformDate(this.data);
  }

  inputEvent(target: any) {
    const targetValue = target.value;
    this.$emit('input', targetValue);
  }
}
