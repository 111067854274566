





















































































import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import { PeschecScreeningStatus, PescheckDatasets, PescheckDatasetsValue, Pescheckv3PescheckDocument }
  from '@/models/identification-requests/pescheck';
import FormTextArea from '@/components/common/form-elements/FormTextArea.vue';
import { Action, State as StateClass } from 'vuex-class';
import Modal from '@/components/common/Modal.vue';
import { State } from '@/models/State';
import { ADD_TOAST_MESSAGE as addToastMessage } from 'vuex-toast';
import { DataContainerStatus } from '@/models/Common';
import { ValidationObserver } from 'vee-validate';

export type PescheckDisplay = {
  status: PeschecScreeningStatus | undefined,
  datasets: PescheckDatasets[] | undefined,
  isFalsePositiveScreening?: boolean,
  isFalsePositivePep?: boolean,
} | null;

@Component({
  components: {
    Modal,
    FormTextArea,
    ValidationObserver,
  },
})

export default class PesCheckModal extends Vue {
  @Prop() pescheck!: Pescheckv3PescheckDocument;
  @Prop() userId!: any;
  @Prop() showModal!: any;

  @StateClass('pescheck') pescheckSlice!: State['pescheck'];

  @Action updatePescheckHitPep!: Function;
  @Action(addToastMessage) addToastMessage!: Function;

  isFalsePositivePep = true;
  pepRationale = '';

  @Watch('pescheckSlice.status')
  onPescheckPepHitStatusChange(newPescheckStatus: DataContainerStatus): void {
    if (this.showModal) {
      if (newPescheckStatus === DataContainerStatus.Success) {
        this.addToastMessage({
          text: `You successfully marked this PEP hit as a ${this.isFalsePositivePep ? 'false positive' : 'true hit'}.`,
          type: 'success',
        });
        this.$emit('close', 'success');
      } else if (newPescheckStatus === DataContainerStatus.Error) {
        this.addToastMessage({
          text: 'Something went wrong.',
          type: 'danger',
        });
        this.$emit('close');
      }
    }
  }

  get isProcessing(): boolean {
    return this.pescheckSlice?.status === DataContainerStatus.Processing;
  }

  get falsePositive(): string {
    if (this.isFalsePositivePep) {
      return 'highlighted-green';
    }
    return 'not-highlighted';
  }

  get trueHit(): string {
    if (!this.isFalsePositivePep) {
      return 'highlighted-red';
    }
    return 'not-highlighted';
  }

  get markHitAs(): string {
    if (!this.isFalsePositivePep) {
      return 'pes_mark-hit--true';
    }

    return 'pes_mark-hit--false';
  }

  updatePotentialHitPep(): void {
    this.updatePescheckHitPep({
      uid: this.userId,
      isFalsePositivePep: this.isFalsePositivePep,
      pepRationale: this.pepRationale,
    });
  }
}
